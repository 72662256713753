import { scrollIntoViewWithOffset } from '../helpers/scroll'

// Function to handle link click event
const handleClick = event => {
  event.preventDefault() // Prevent default link behavior

  // Get the target element to scroll to
  const targetId = event.target.getAttribute('href').substring(1)

  // Scroll to the target element with an offset
  scrollIntoViewWithOffset(document.getElementById(targetId), 80)

  // Update the URL with the hashtag
  window.history.pushState(null, null, `#${targetId}`)
}

// Find all links with class "toc-links" and add event listener
document.querySelectorAll('.toc-links a').forEach(link => {
  link.addEventListener('click', handleClick)
})
